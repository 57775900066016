.button-bg {
  padding: 12px 20px 12px 20px;
  border-radius: 15px;
  background: #FBDC2A;
  border: none;
}

.bg-btn-shadow {
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
}

.button-contact-us {
  padding: 16px 24px 16px 24px;
  border-radius: 15px;
  background: #FBDC2A;
  border: none;
}

@include media-breakpoint-down(sm) {
  .button-contact-us {
    padding: 12px 36px 12px 36px;
  }
}

