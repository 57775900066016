//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------


@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/*
 * Example for loading multiple fonts at once
 *
@include googleFonts((
  (
    "name": "Roboto",
    "folder": "Roboto",
    "files": "Roboto",
    "weights": (300, 400, 700)
  ),
  (
    "name": "Besley",
    "folder": "Besley",
    "files": "Besley",
    "weights": (300, 400, 700)
  ),
));
*/

@font-face {
  font-family: "atyp";
  src: url('../../../../public/images/atyp-font/atyp-font-family/AtypTextTRIAL-Regular-BF65727125ea126.otf')  format('truetype');
}

.font-family-atyp{
  font-family: "atyp";
}


//font colors

.font-color-neutral-dark {
  color: #1D1D1D;
}

.font-color-neutral-grey-darker {
  color: #686868;
}

.font-color-green {
  color: #B4DB36;
}

.font-color-dark-green {
  color: #258E3F;
}

.font-color-primary {
  color: #10C0DF;
}

.font-color-secondary-default {
  color: #124232;
}

.font-color-lightest-yellow {
  color: #F7F7BB;
}

.font-color-grey-light {
  color: #8E8E8E;
}

.font-color-secondary-darker {
  color: #485E16;
}

.font-color-primary-lightest {
  color: #FEF8D4;
}

.font-color-primary-yellow {
  color: #FBDC2A;
}


//font sizes

.font-size-92 {
  font-size: 92px;
}
@include media-breakpoint-down(sm) {
  .font-size-92 {
    font-size: 32px;
  }
}

.font-size-32 {
  font-size: 32px;
}

@include media-breakpoint-down(sm) {
  .font-size-32 {
    font-size: 20px;
  }
}

.font-size-18 {
  font-size: 18px;
}

@include media-breakpoint-down(sm) {
  .font-size-18 {
    font-size: 14px;
  }
}

.font-size-56 {
  font-size: 56px;
}

@include media-breakpoint-down(sm) {
  .font-size-56 {
    font-size: 24px;
  }
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-24 {
  font-size: 24px;
}

@include media-breakpoint-down(sm) {
  .font-size-24 {
    font-size: 20px;
  }
}

.font-size-40 {
  font-size: 40px;
}

@include media-breakpoint-down(sm) {
  .font-size-40 {
    font-size: 24px;
  }
}

.font-size-16 {
  font-size: 16px;
}

@include media-breakpoint-down(sm) {
  .font-size-16 {
    font-size: 14px;
  }
}

// font-weight

.font-weight-600p {
  font-weight: 600;
}

.font-weight-500p {
  font-weight: 500;
}

.font-weight-400p {
  font-weight: 400;
}
