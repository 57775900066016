//navbar

.bg-color-navbar {
  background: rgba(18, 66, 50, 0.3);
}

.bg-scroll-navbar-color {
  background-color: #D0D9D6;
}

.bg-side-nav-color {
  background: rgba(18, 66, 50, 0.4);
}

//hero section

.bg-hero-section-container {
  background-image: url("../../../../public/images/landing/bg-hero-section-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@include media-breakpoint-up(md) {
  .bg-hero-section-container {
    min-height: 100vh;
  }
}

.bg-container-shadow {
  box-shadow: 0px 5px 30px 20px rgba(0, 0, 0, 0.12);
}

.bg-form-shadow {
  box-shadow: 0px 5px 30px 20px rgba(0, 0, 0, 0.05);
}

.bg-dropdown-shadow {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}


.icons-container {
  min-height: 100vh;
  padding: 0px 56px 0px 56px;
}

.bg-about-us-container {
  padding: 105px 56px 105px 160px;
}

@include media-breakpoint-down(sm) {
  .bg-about-us-container {
    padding: 40px 46px 40px 46px;
  }
}

.bg-green-color {
  background: #B4DB36;
}

.bg-light-green {
  background: #D7F4DE;
}

.bg-light-red {
  background: #FFCFCC;
}

.bg-dark-green {
  background-color: #124232;
}

.bg-primary-lightest {
  background: #EDFDFE;
}

.bg-light-grey-color {
  background: rgba(18, 66, 50, 0.1);
}

.bg-about-content-container {
  padding: 32px 160px 32px 80px
}

@include media-breakpoint-down(sm) {
  .bg-about-content-container {
    padding: 24px 16px 24px 16px;
  }
}


.bg-color-light-green-color {
  background: rgba(237, 242, 222, 1);
}

.bg-white-color {
  background: rgba(255, 255, 255, 0.1);
}

//footer

.bg-footer-container {
  background-color: #1D1D1D;
}

.bg-secondary-lightest {
  background: #FAFDE8;
}
