
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}


body{
  font-family: "atyp", serif !important;
}

@include media-breakpoint-up(md) {
  .job-opening-container {
    height: 100vh;
    overflow-y: scroll;
  }
  .sticky-container {
    position: sticky; /* Sticky position */
    top: 0; /* Stick to the top of the container */
    flex-shrink: 0;
    height: 100vh;
  }

  .job-opening-container::-webkit-scrollbar {
    visibility: hidden;
  }
}

.services-button {
  cursor: pointer;
}


.br-8px {
  border-radius: 8px;
}

.br-16px {
  border-radius: 16px;
}

.nav-link {
  color: #ffffff !important;
}

@include media-breakpoint-down(sm) {
  .nav-link {
    color: #124232 !important;
  }
}

.dropdown-menu.show {
  border: none;
  //box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

@include media-breakpoint-up(md) {
  .dropdown-menu.show {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }
}

.other-page-nav-color .nav-item .nav-link {
  color: #124232 !important;
}

.footer-logo-container {
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 4px;
}


input::placeholder, textarea::placeholder {
  color: #8E8E8E !important;
}

.hyper-link {
  color: #FBDC2A !important;
}
